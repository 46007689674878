import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="チェックボックス - コンポーネント" mdxType="SEO" />
    <PageTitle title="チェックボックス" enTitle="Checkbox" mdxType="PageTitle" />
    <p>{`主に複数の項目から任意の項目を選択するとき、または選択・未選択を切り替える時に使用します。`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ameba-spindle.web.app/?path=/docs/form-checkbox--checkbox"
        }}>{`Storybook`}</a></li>
    </ul>
    <h2 {...{
      "id": "パターン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%91%E3%82%BF%E3%83%BC%E3%83%B3",
        "aria-label": "パターン permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`パターン`}</h2>
    <h3 {...{
      "id": "単一項目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%8D%98%E4%B8%80%E9%A0%85%E7%9B%AE",
        "aria-label": "単一項目 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`単一項目`}</h3>
    <p>{`単一の項目に対して、選択・未選択を切り替える時に使用します。
送信、確定ボタンを押すことによって、選んだ選択肢が有効になります。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f24dffc4f5b325dd8c4deed503fd21e1/29007/checkbox-single-use.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "38.75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f24dffc4f5b325dd8c4deed503fd21e1/2aaaf/checkbox-single-use.webp 160w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/85e47/checkbox-single-use.webp 320w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/75198/checkbox-single-use.webp 640w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/691bc/checkbox-single-use.webp 960w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/223e5/checkbox-single-use.webp 1280w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/2158a/checkbox-single-use.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/f24dffc4f5b325dd8c4deed503fd21e1/69538/checkbox-single-use.png 160w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/72799/checkbox-single-use.png 320w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/6af66/checkbox-single-use.png 640w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/d9199/checkbox-single-use.png 960w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/21b4d/checkbox-single-use.png 1280w", "/static/f24dffc4f5b325dd8c4deed503fd21e1/29007/checkbox-single-use.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/f24dffc4f5b325dd8c4deed503fd21e1/6af66/checkbox-single-use.png",
              "alt": "メールアドレス入力フィールドの下に、メール通知・メールマガジンの受け取り可否の単一項目のチェックボックスがある例",
              "title": "メールアドレス入力フィールドの下に、メール通知・メールマガジンの受け取り可否の単一項目のチェックボックスがある例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h3 {...{
      "id": "複数項目",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%A4%87%E6%95%B0%E9%A0%85%E7%9B%AE",
        "aria-label": "複数項目 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`複数項目`}</h3>
    <p>{`複数の項目を選択させる時に使用します。
送信、確定ボタンを押すことによって、選んだ選択肢が有効になります。`}</p>
    <p>{`項目のレイアウトは、その個数やレイアウトに応じて判断してください。
項目数が多数の場合、不特定多数の場合には`}<a parentName="p" {...{
        "href": "https://ameba-spindle.web.app/?path=/story/form-dropdown--drop-down"
      }}>{`DropDown`}</a>{`等を検討してください。`}</p>
    {
      /* TODO: DropDownのページができたらそちらを参照する */
    }
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/0b87a2a5b48850b00bdd19a64afc6ca4/29007/checkbox-multiple-use.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0b87a2a5b48850b00bdd19a64afc6ca4/2aaaf/checkbox-multiple-use.webp 160w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/85e47/checkbox-multiple-use.webp 320w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/75198/checkbox-multiple-use.webp 640w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/691bc/checkbox-multiple-use.webp 960w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/223e5/checkbox-multiple-use.webp 1280w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/2158a/checkbox-multiple-use.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/0b87a2a5b48850b00bdd19a64afc6ca4/69538/checkbox-multiple-use.png 160w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/72799/checkbox-multiple-use.png 320w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/6af66/checkbox-multiple-use.png 640w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/d9199/checkbox-multiple-use.png 960w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/21b4d/checkbox-multiple-use.png 1280w", "/static/0b87a2a5b48850b00bdd19a64afc6ca4/29007/checkbox-multiple-use.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/0b87a2a5b48850b00bdd19a64afc6ca4/6af66/checkbox-multiple-use.png",
              "alt": "ボタンのレイアウト例",
              "title": "ボタンのレイアウト例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h2 {...{
      "id": "状態",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E7%8A%B6%E6%85%8B",
        "aria-label": "状態 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`状態`}</h2>
    <p>{`Checkboxの選択・未選択、非活性時の表示と、Tabキー操作によるフォーカスのスタイルです。`}</p>
    <p>{`
  `}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/7acca3f8c41da7a5550d840e02fd88a1/29007/checkbox-state.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7acca3f8c41da7a5550d840e02fd88a1/2aaaf/checkbox-state.webp 160w", "/static/7acca3f8c41da7a5550d840e02fd88a1/85e47/checkbox-state.webp 320w", "/static/7acca3f8c41da7a5550d840e02fd88a1/75198/checkbox-state.webp 640w", "/static/7acca3f8c41da7a5550d840e02fd88a1/691bc/checkbox-state.webp 960w", "/static/7acca3f8c41da7a5550d840e02fd88a1/223e5/checkbox-state.webp 1280w", "/static/7acca3f8c41da7a5550d840e02fd88a1/2158a/checkbox-state.webp 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/7acca3f8c41da7a5550d840e02fd88a1/69538/checkbox-state.png 160w", "/static/7acca3f8c41da7a5550d840e02fd88a1/72799/checkbox-state.png 320w", "/static/7acca3f8c41da7a5550d840e02fd88a1/6af66/checkbox-state.png 640w", "/static/7acca3f8c41da7a5550d840e02fd88a1/d9199/checkbox-state.png 960w", "/static/7acca3f8c41da7a5550d840e02fd88a1/21b4d/checkbox-state.png 1280w", "/static/7acca3f8c41da7a5550d840e02fd88a1/29007/checkbox-state.png 1600w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/7acca3f8c41da7a5550d840e02fd88a1/6af66/checkbox-state.png",
              "alt": "ボタンのレイアウト例",
              "title": "ボタンのレイアウト例",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span>{`
`}</p>
    <h2 {...{
      "id": "アクセシビリティ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%A2%E3%82%AF%E3%82%BB%E3%82%B7%E3%83%93%E3%83%AA%E3%83%86%E3%82%A3",
        "aria-label": "アクセシビリティ permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`アクセシビリティ`}</h2>
    <p>{`現在作成中です。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      